<script setup lang="ts">
import {
  AuthorizationError,
  createSelectOptions,
  FRONTEND_CONTENT_TYPES,
  NEWS_TYPE_ENUM,
  POSTIT_CATEGORY_ENUM,
  type FrontContent,
  type FrontContentType,
  type OverviewContentType,
  type Pagination,
  type V1GetOptions,
} from "@gasparigit/omnia-sdk";
import { FRONT_TYPES_MAP } from "~/lib/const";
import { isNotAllowed, link } from "~/lib/utils/contents";
import { processError } from "~/lib/utils/errors";
import { defineSeo } from "~/lib/utils/seo";
import Filter from "../icons/Filter.vue";

const omniaSdk = useOmniaSdk();
const route = useRoute();
const hubId = useParamId();
const refMenus = useRefMenus();

const selectedTab = ref<FrontContentType | "all">((route.query.section as FrontContentType) || "all");
const page = usePage();

const defaultOptions: V1GetOptions = {
  future: true,
  past: true,
  order_mode: (useRoute().query["order"] as "asc" | "desc") || (selectedTab.value === "scadenzario" ? "asc" : "desc"),
  news_types: null,
  category_types: null,
  publishers: null,
  from_date: useRoute().query["from"] ? new Date(useRoute().query["from"] as string) : new Date(),
  to_date: useRoute().query["to"] ? new Date(useRoute().query["to"] as string) : null,
};

const options = ref<V1GetOptions>(defaultOptions);

const scadenzarioFilter = computed(() => {
  return [options.value.future && "next_date", options.value.past && "past_date"];
});

const { data: area, error: areaError } = await useAsyncData(`area-${route.params.area_slug}`, async () => {
  if (!route.params.area_slug) return;

  try {
    return await omniaSdk.v1.areas.get(route.params.area_slug as string);
  } catch (error) {
    processError(error);
  }
});

if (areaError.value) processError(areaError.value);

const {
  data: hub,
  error: hubError,
  status: hubStatus,
} = await useAsyncData(`hub-${hubId}`, async () => {
  try {
    return await omniaSdk.v1.hubs.get(hubId, { full: true });
  } catch (error) {
    processError(error);
  }
});

if (hubError.value) processError(hubError.value);

defineSeo(hub.value);

const { data: menus } = await useAsyncData(`ref-menus-${refMenus?.join(".")}`, async () => {
  if (refMenus.length === 0) return false;

  try {
    return await Promise.all(refMenus.map((id) => omniaSdk.v1.menus.get(id)));
  } catch (error) {}

  return false;
});

const { data: publishers } = useAsyncData(
  "all-publishers",
  async () => {
    try {
      if (!(selectedTab.value === "normativa" || selectedTab.value === "portali")) return null;

      return await omniaSdk.v1.publishers.list({ hub: hubId, type: selectedTab.value === "normativa" ? "norme" : "links" });
    } catch (error) {
      processError(error);
    }
  },
  {
    watch: [selectedTab],
  }
);

const { data, status, refresh } = useAsyncData(`hub-${hubId}-${selectedTab.value}`, async () => {
  try {
    if (selectedTab.value === "all") return await omniaSdk.v1.contents.all({ hub: hubId }, { pagination: "cursor" });
    else return await omniaSdk.v1.contents.list({ hub: hubId }, selectedTab.value, { pagination: selectedTab.value === "pratiche" ? "none" : "classic", page: page.value }, options.value);
  } catch (error) {
    if (error instanceof AuthorizationError) {
      return { allowed: false };
    }

    processError(error);
  }
});

const { data: highlighted } = useAsyncData(`hub-${hubId}-highlighted`, async () => {
  try {
    if (selectedTab.value === "all") return await omniaSdk.v1.contents.highlighted({ hub: hubId }, { pagination: "none" });
    return null;
  } catch (error) {
    if (!(error instanceof AuthorizationError)) {
      processError(error);
    }
  }
});

watch(
  () => route.query,
  () => {
    selectedTab.value = (route.query.section as FrontContentType) || "all";
  }
);

watch(selectedTab, () => {
  useRouter().push({ query: { ...route.query, section: selectedTab.value, page: 1 } });

  page.value = 1;
  options.value = defaultOptions;

  if (selectedTab.value === "scadenzario" || selectedTab.value === "scadenzari-elettorali") {
    options.value.order_mode = "asc";
  } else {
    options.value.order_mode = "desc";
  }

  refresh();
});

watch(options.value, (val, oldVal) => {
  if (val !== oldVal) {
    useRouter().push({ query: { ...useRoute().query, order: val.order_mode, from: val.from_date?.toISOString(), to: val.to_date?.toISOString() } });
  }

  refresh();
});

function isAll(data: any): data is OverviewContentType {
  return data && selectedTab.value === "all";
}

function isFrontContent(data: any): data is Pagination<FrontContent> {
  return data && selectedTab.value !== "all";
}

function openReadAll(frontType: string) {
  selectedTab.value = frontType as FrontContentType;

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}
</script>

<template>
  <FrontBreadcrumHero
    :breadcrumbs="
      [
        area && { href: link({ area }), title: area.title },
        ...(menus ? menus.map((m, i) => ({ title: m.title, href: link({ area, chain: menus ? menus.slice(0, i + 1) : undefined }) })) : []),
        { title: hub?.title },
      ].filter((f) => !!f)
    "
    :title="hub?.title"
    :abstract="hub?.abstract"
    :search="false"
    :back-url="area ? link({ area, chain: menus ? menus : undefined }) : undefined"
    :skeleton="!hub && hubStatus === 'pending'"
  ></FrontBreadcrumHero>

  <div class="main-content mb-10">
    <FrontUnauthorized v-if="isNotAllowed(data)" :link-config="{ area, chain: menus ? menus : undefined, hub }" />
    <Tabs defaultValue="all" v-model="selectedTab" v-else>
      <TabsList class="tab-list">
        <TabsTrigger value="all" class="tab-nav">Tutto</TabsTrigger>
        <template v-for="tab in Object.values(FRONT_TYPES_MAP)">
          <TabsTrigger v-if="FRONT_TYPES_MAP[tab.type].show_in_tabs && hub?.contents_count && hub.contents_count[tab.type] > 0" :value="tab.type" class="tab-nav">{{
            FRONTEND_CONTENT_TYPES[tab.type]
          }}</TabsTrigger>
        </template>
      </TabsList>
      <TabsContent value="all">
        <FrontContentsOverview
          :settings="hub?.settings"
          v-model:highlighted="highlighted"
          v-if="status !== 'pending' && isAll(data)"
          v-model="data"
          :link-config="{ area, hub, chain: menus ? menus : undefined }"
          :open-read-all="openReadAll"
          :readAllTo="(frontType) => link({ area, hub, frontType })"
        />
        <Skeleton v-else class="w-full h-52 rounded-lg" />
      </TabsContent>
      <template v-for="tab in hub?.settings">
        <TabsContent :value="tab.type">
          <FrontContentsList
            v-if="status !== 'pending' && isFrontContent(data)"
            :key="tab.type"
            :title="FRONTEND_CONTENT_TYPES[tab.type]"
            :icon="FRONT_TYPES_MAP[tab.type].icon"
            :link-config="{ area, hub, chain: menus ? menus : undefined }"
            :show-children="tab.type !== 'pratiche' || data?.data.length == 1"
            :has-side="selectedTab !== 'pratiche'"
            v-model:data="data"
            v-model:page="page"
            @update:page="refresh()"
          >
            <template #side>
              <FrontCheckboxCard v-if="selectedTab === 'postit'" base-key="category_types" v-model="options.category_types" :options="createSelectOptions(POSTIT_CATEGORY_ENUM)" />
              <FrontCheckboxCard v-if="selectedTab === 'notizie'" base-key="news_type" v-model="options.news_types" :options="createSelectOptions(NEWS_TYPE_ENUM)" />

              <FrontPrimaryCard :icon="Filter" title="Filtra per data" v-if="selectedTab === 'scadenzario'">
                <div class="mb-3">
                  <FormField name="filter_date_from" v-slot="{ componentField }">
                    <FormItem>
                      <FormLabel>Dal</FormLabel>
                      <DatePicker v-bind="componentField" v-model="options.from_date" :has-input="false" @update:model-value="refresh()"></DatePicker>
                    </FormItem>
                  </FormField>
                </div>
                <div>
                  <FormField name="filter_date_to" v-slot="{ componentField }">
                    <FormItem>
                      <FormLabel>Al</FormLabel>
                      <DatePicker v-bind="componentField" v-model="options.to_date" :has-input="false" @update:model-value="refresh()"></DatePicker>
                    </FormItem>
                  </FormField>
                </div>
              </FrontPrimaryCard>

              <FrontCheckboxCard
                v-if="(selectedTab === 'portali' || selectedTab === 'normativa') && publishers?.data.length"
                base-key="publisher"
                name="publisher"
                :options="publishers.data.map((p) => ({ label: p.title, value: p.id }))"
                v-model="options.publishers"
              />

              <FrontRadioGroupCard v-if="selectedTab !== 'pratiche'" base-key="order" v-model="options.order_mode"></FrontRadioGroupCard>
            </template>
          </FrontContentsList>
          <Skeleton v-else class="w-full h-52 rounded-lg" />
        </TabsContent>
      </template>
    </Tabs>
  </div>

  <AdminLinkToAdmin :config="{ hub }" />
</template>
